import axios from "axios";
import { createContext, useEffect, useRef, useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";

import { useDecryptedClientId } from "./hook/useDecryptedClientId";
import { getCategory, getNews } from "./api";

import { Header } from "./components/Header";
import { Home } from "./pages/Home";
import { News } from "./pages/News";

import "./App.scss";

import "react-spring-bottom-sheet/dist/style.css";

export const Context = createContext({});

function App() {
  const ref = useRef(null);
  const refMouse = useRef(null);
  const [searchParams] = useSearchParams();
  const [modeDisplay, setModeDisplay] = useState(false);
  const [search, setSearch] = useState("");
  const [searchMobile, setSearchMobile] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [locationMob, setLocationMob] = useState(false);
  const [inFocus, setInFocus] = useState(false);

  const [modal, setModal] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const [categories, setCategories] = useState([]);
  const [partners, setPartners] = useState([]);

  const { decryptedClientId, decryptClientId } = useDecryptedClientId();

  const [data, setData] = useState([]);
  const [selectFilter, setSelectFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [city, setCity] = useState({ center: [55.796127, 49.106414], zoom: 10 });

  const [news, setNews] = useState([]);
  const map = useRef(null);

  const modeHandler = () => {
    setModeDisplay(!modeDisplay);
  };

  useEffect(() => {
    let clientId = searchParams.get("client_id");

    if (clientId) {
      clientId = clientId.replace(/ /g, "+");
      decryptClientId(clientId, process.env.REACT_APP_SECRET_KEY);
    }
  }, []);

  useEffect(() => {
    getCategory().then((category) => {
      setCategories(category);
      setIsLoading(false);
    });

    async function fetchData(clientId) {
      const resData = await Promise.allSettled([
        axios.get(`https://api.card-rt.ru/partner/default/get-partners`),
        axios.get(
          `https://api.card-rt.ru/lkb/lkb/get-partners${clientId ? `?client_id=${clientId}` : ""}`,
        ),
      ]);
      setPartners(
        clientId
          ? [...resData[0]?.value.data, ...resData[1]?.value.data.map((data) => data.partner)]
          : [...resData[0]?.value.data, ...resData[1]?.value.data],
      );
      setData(
        clientId
          ? [...resData[0]?.value.data, ...resData[1]?.value.data.map((data) => data.partner)]
          : [...resData[0]?.value.data, ...resData[1]?.value.data],
      );
    }

    fetchData(decryptedClientId);

    getNews().then((dataNews) => {
      setNews(dataNews);
      setIsLoading(false);
    });
  }, [decryptedClientId]);

  const locationHandler = (e) => {
    if (document.body.classList.contains("noscroll")) {
      document.body.classList.remove("noscroll");
    }
    document.body.classList.add("noscroll");
    setLocationMob(!locationMob);
  };

  const [countSearch, setCountSearch] = useState(0);
  const searchHandler = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
    setIsEmpty(false);
    let res = data.filter(
      (el) =>
        (el.title && el.title.toLowerCase().search(e.target.value.toLowerCase()) !== -1) ||
        (el.name && el.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1),
    );
    setCountSearch(res.length);
    if (!search) {
      setPartners(data);
    } else {
      setPartners(res);
    }
  };

  const focusHandler = (e) => {
    if (e.target.value === "") {
      setIsEmpty(true);
    }
  };

  const searchModalHandler = (e) => {
    document.body.classList.toggle("noscroll");
    setSearchMobile(!searchMobile);
    if (searchMobile) {
      setSearchMobile(false);
      setSearch("");
      setPartners(data);
    }
    if (isEmpty) setIsEmpty(false);
  };

  const modalOpen = (partner, searchHistory = false) => {
    document.body.classList.toggle("noscroll");
    setModal(!modal);
    setDataModal(partner);
    if (searchHistory === true) {
      let savedName = JSON.parse(localStorage.getItem("history")) || [];
      const filterHistory = savedName.filter(
        (el) => (partner.title && el !== partner.title) || (partner.name && el !== partner.name),
      );
      filterHistory.push((partner.title && partner.title) || (partner.name && partner.name));
      localStorage.setItem("history", JSON.stringify(filterHistory));
    }

    if (searchMobile) {
      setSearchMobile(false);
      setSearch("");
      setPartners(data);
      setIsEmpty(false);
    }
  };

  useEffect(() => {
    const clickHandler = (e) => {
      if (!searchMobile) return;
      if (ref.current && !ref.current.contains(e.target)) {
        setSearchMobile(!searchMobile);
        setIsEmpty(false);
        setSearch("");

        if (document.body.classList.contains("noscroll")) {
          document.body.classList.remove("noscroll");
        }
      }
    };
    document.addEventListener("touchstart", clickHandler);

    return () => {
      document.removeEventListener("touchstart", clickHandler);
    };
  }, [searchMobile]);

  const categoryHandler = (e) => {
    if (e.target.checked) {
      const res = data.filter((el) => el.category && el.category.id == e.target.value);
      setSelectFilter([...selectFilter, ...res]);
    } else {
      const res = selectFilter.filter((el) => el.category && el.category.id != e.target.value);
      setSelectFilter([...res]);
    }
  };

  const [isDragging, setIsDragging] = useState(false);
  const [isStartY, setIsStartY] = useState(null);
  const [isStartHeight, setIsStartHeight] = useState(null);

  const updateSheetHeight = (h) => {
    refMouse.current.style.height = `${h}vh`;
  };

  useEffect(() => {
    const draggingStart = (e) => {
      if (refMouse.current && !refMouse.current.contains(e.target)) {
        setIsDragging(true);
        setIsStartY(e.pageY);
        setIsStartHeight(refMouse.current.style.height);
      }
    };
    document.addEventListener("touchstart", draggingStart);
    return () => {
      document.removeEventListener("touchstart", draggingStart);
    };
  }, [isDragging]);

  useEffect(() => {
    const dragging = (e) => {
      if (refMouse.current && !refMouse.current.contains(e.target)) {
        if (!isDragging) return;
        refMouse.current.style.height = `${e.pageY}vh`;
        updateSheetHeight(isStartHeight + (isStartY / window.innerHeight) * 100);
      }
    };

    document.addEventListener("touchmove", dragging);
    return () => {
      document.removeEventListener("touchmove", dragging);
    };
  }, [isDragging]);

  useEffect(() => {
    const draggingStop = (e) => {
      if (refMouse.current && !refMouse.current.contains(e.target)) {
        setIsDragging(false);
        const sheetHeight = refMouse.current.style.height;
        sheetHeight < 25
          ? setModal(false)
          : sheetHeight > 75
          ? updateSheetHeight(100)
          : updateSheetHeight(50);
        if (document.body.classList.contains("noscroll")) {
          document.body.classList.remove("noscroll");
        }
      }
    };

    document.addEventListener("touchend", draggingStop);
    return () => {
      document.removeEventListener("touchend", draggingStop);
    };
  }, [isDragging]);

  const onCityClick = (e) => {
    const coord = e.target.id.split(",").map((item) => parseFloat(item));
    setCity({ center: coord, zoom: 10 });
    if (map.current) {
      map.current.setCenter(coord);
    }
  };

  const getMyLocation = (e) => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      const { latitude, longitude } = coords;
      const geolocationCoord = [latitude, longitude];
      setCity({ center: geolocationCoord, zoom: 9 });
      if (map.current) {
        map.current.setCenter(geolocationCoord);
      }
    });
  };

  return (
    <Context.Provider
      value={{
        inFocus,
        setInFocus,
        modeDisplay,
        setModeDisplay,
        modeHandler,
        modalOpen,
        modal,
        search,
        setSearch,
        isEmpty,
        setIsEmpty,
        searchModalHandler,
        searchMobile,
        ref,
        searchHandler,
        focusHandler,
        locationHandler,
        locationMob,
        categories,
        partners,
        dataModal,
        data,
        countSearch,
        setPartners,
        categoryHandler,
        selectFilter,
        refMouse,
        isLoading,
        city,
        onCityClick,
        getMyLocation,
        news,
        map,
        setModal,
      }}
    >
      <div className="wrapper">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/news" element={<News />} />
          <Route path="/nonheader" element={<Home />} />
        </Routes>
      </div>
    </Context.Provider>
  );
}

export default App;
